body {
  font-family: "Barlow", sans-serif;
}

:root {
  /* font sizes */
  --sectionHeaderTextSize: 50px;
  --smallTexts: 12px;
  /* Colors */
  --longTextLight: rgba(109, 103, 103, 0.897);
}

.section-header {
  font-size: var(--sectionHeaderTextSize);
  font-weight: 900;
  line-height: 1.1;
}
/* Hidden state */

.navbar {
  position: fixed;
  top: -80px; /* Adjust this value to control how far the navbar is hidden */
  left: 0;
  right: 0;
  background-color: #f1f1f1;
  transition: top 0.3s ease-in-out;
}

/* Visible state */
.navbar.visible {
  top: 0;
}

.darkGlobalBackground {
  background-color: #0d1224;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.global-grid-container {
  border: 1px solid rgb(228, 228, 228);
}

.right-details-container {
  margin-left: 50px;
}
.lightText {
  color: var(--longTextLight);
}
.italic {
  font-style: italic;
}

.alignment {
  display: flex;
  align-items: center;
  gap: 16px;
}
.text-link-container {
  border-bottom: 2px solid #37b1d1;
  cursor: pointer;
  width: 120px;
}
.text-link-container:hover {
  color: #37b1d1;
  font-weight: 500;
}
.small-text-link {
  margin: 0;
}
.g-img-container {
  position: relative;
}

/* embla css */
.section-header {
  margin-top: 0;
}
.slider-container {
  width: 300px;
  margin: 20px;
}

.slider {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  position: relative;
}

.slider-thumb {
  width: 20px;
  height: 20px;
  background-color: #4caf50;
  position: absolute;
  border-radius: 50%;
  cursor: grab;
}
