.play-button {
  position: relative;
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
}

.layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #3498db; /* Set your button color */
  border-radius: 50%;
  animation: pulse 4.5s ease-in-out infinite;
  opacity: 0;
}

.layer:nth-child(2) {
  animation-delay: 1.5s;
}

.layer:nth-child(3) {
  animation-delay: 3s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.5);
    opacity: 0 ;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}
.play {
  color: #fff; /* Set the color of the play button */
  font-size: 12px; /* Set the font size of the play button */
  position: relative;
  z-index: 1; /* Ensure the play button appears above the layers */
}
