.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  opacity: 0;
  animation: fadeIn 1s forwards, moveInLeft 1s forwards, moveInRight 1s forwards,
    moveInSlash 1s forwards;
  animation-delay: 0s, 1s, 2s, 3s;
}

.loading-icon {
  opacity: 1;
  color: red;
}

/* @keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveInLeft {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveInRight {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveInSlash {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
} */
