.std-main-banner-container {
  height: 90vh;
  width: 100%;

  /* background-color: rgb(0, 182, 127); */
  background: rgb(19, 251, 233);
  background: linear-gradient(
    140deg,
    rgba(19, 251, 233, 1) 0%,
    rgba(68, 177, 163, 1) 54%,
    rgba(2, 156, 185, 1) 100%
  );
  position: relative;
}

.std-left-container h1 {
  margin-top: 0;
}

.std-left-container p {
  margin-bottom: 0;
}
.std-left-container p:nth-child(2) {
  color: white;
}

.std-left-container .watch-demo-container {
  display: flex;
  align-items: center;
  gap: 20px;
  transform: translateX(-35%);
}

.std-left-container .watch-demo-container p {
  margin: 0;
}

.std-right-container .std-img-container {
  height: 600px;
}

.std-right-container .std-img-container .img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.std-main-banner-container .banner-img {
}
