.module-card-container {
  /* background-color: #0d1224 !important; */
  height: 100%;
  width: 100%;
  padding: 20px;
  padding: 80px 20px;
}
.module-card-container .tagline-text {
  color: white;
  text-align: center;
  font-size: 50px;
  margin: 2px 0;
}
.module-card-container .text {
  color: white;
  font-size: 14px;
  padding-top: 10px;
  margin: 20px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
}
.module-card {
  border-radius: 10px;
  border: 0.3px solid rgba(236, 233, 233, 0.219);
  height: 350px;
  margin-bottom: 20px;
  box-shadow: 1px 4px 18px -5px rgba(242, 242, 242, 0.26);
  -webkit-box-shadow: 1px 4px 18px -5px rgba(242, 242, 242, 0.26);
  -moz-box-shadow: 1px 4px 18px -5px rgba(242, 242, 242, 0.26);

  /* background-color: #12172a !important; */
  background: rgb(1, 20, 32);
  background: linear-gradient(
    90deg,
    rgba(1, 20, 32, 1) 0%,
    rgba(27, 25, 71, 1) 76%,
    rgb(31, 45, 82) 100%
  );
}
.module-name h4 {
  color: white;
  /* margin: 2px 0; */
  margin: 20px 20px 2px 20px;
  font-size: 18px;
  position: relative;
  overflow: hidden;
}
.module-name h4::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgb(129, 18, 111) 0%,
    rgba(41, 132, 156, 1) 53%,
    rgb(95, 193, 75) 100%
  ); /* Set the color of the underline */
  animation: loadUnderline 2s ease forwards; /* Animation properties */
}
@keyframes loadUnderline {
  to {
    width: 100%; /* Set final width to 100% */
  }
}
.module-desc {
  color: white;
  font-size: 12px;
  padding: 2px 20px 2px 20px;
}
.module-desc p {
  margin: 0;
  opacity: 0.6;
}
.module-img-container {
  width: 100%;
  height: 180px;
  /* border: 1px dashed rgb(18, 129, 76); */
  background: rgb(18, 129, 76);
  background: linear-gradient(
    90deg,
    rgba(18, 129, 76, 1) 0%,
    rgba(41, 132, 156, 1) 53%,
    rgba(75, 140, 193, 1) 100%
  );
  border-radius: 10px 10px 0 0;

  color: white;
  font-size: 50px;
  position: relative;
}

.module-img-container .container video {
  position: absolute;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  object-fit: contain;
}

/* ums page section styling */
.ums-main-page-container {
  position: relative;
  background: rgb(3, 27, 42);
  background: linear-gradient(
    90deg,
    rgba(3, 27, 42, 1) 0%,
    rgba(5, 3, 47, 1) 71%,
    rgba(5, 29, 89, 1) 100%
  );
}

/* small devices */
@media screen and (max-width: 768px) {
  .module-card-container .tagline-text {
    font-size: 20px;
    margin-bottom: 2px;
  }
  .module-card-container .text {
    font-size: 12px;
  }
}
