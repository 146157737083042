.banner-main-wrapper {
  height: 100%;
  width: 100%;
  max-width: 100%;
  /* box-shadow: -1px 10px 10px -6px rgba(224, 224, 224, 0.46);
  -webkit-box-shadow: -1px 10px 10px -6px rgba(224, 224, 224, 0.46);
  -moz-box-shadow: -1px 10px 10px -6px rgba(224, 224, 224, 0.46); */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.banner-content-wrapper {
  height: 100%;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-color: #f5f7fb; */
}

.banner-content-wrapper .tagline-text {
  font-size: 50px;
  padding: 20px;
  color: #02151f;
  margin-bottom: 0;
  padding-bottom: 2px;
}

.tagline-wrapper {
  width: 100%;
  height: 100%;
}

.floating-images-container {
  position: absolute;
  right: 140px;
}
.floating-images-container img:nth-child(1) {
  width: 120px;
  height: auto;
  margin: 20px;
  box-shadow: 2px -1px 101px 3px rgba(240, 237, 237, 0.8);
  -webkit-box-shadow: 2px -1px 101px 3px rgba(240, 237, 237, 0.8);
  -moz-box-shadow: 2px -1px 101px 3px rgba(240, 237, 237, 0.8);
  animation: moveUpDown 2s infinite alternate;
  position: absolute;
  top: -20px;
  left: -40px;
  z-index: 100;
  background-color: transparent;
  padding: 5px;
}

.floating-images-container img:nth-child(2) {
  width: 500px;
  height: auto;
  border-radius: 15px;
  margin: 20px;
  padding: 5px;
  background-color: white;

  box-shadow: 2px -1px 101px 3px rgba(240, 237, 237, 0.8);
  -webkit-box-shadow: 2px -1px 101px 3px rgba(240, 237, 237, 0.8);
  -moz-box-shadow: 2px -1px 101px 3px rgba(240, 237, 237, 0.8);
  animation: moveUpDown 3s infinite alternate;
}
.floating-images-container img:nth-child(3) {
  width: 200px;
  height: auto;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  border-radius: 15px;

  box-shadow: 2px -1px 101px 3px rgba(240, 237, 237, 0.8);
  -webkit-box-shadow: 2px -1px 101px 3px rgba(240, 237, 237, 0.8);
  -moz-box-shadow: 2px -1px 101px 3px rgba(240, 237, 237, 0.8);
  animation: moveLeftRight 3s infinite alternate;

  position: absolute;
  bottom: 0;
  right: 0;
}

.watch-video-container {
  display: flex;
  gap: 20px;
  font-size: 12px;
  color: #063853;
  align-items: center;
}

/* keyframes from animation */
@keyframes moveUpDown {
  0% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(-5px);
  }
}

@keyframes moveLeftRight {
  0% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(-5px);
  }
}

/* medium devices */

@media screen and (max-width: 768px) {
  .banner-content-wrapper {
    /* height: 250px; */
    width: 100%;
    display: flex;
    flex-direction: column-reverse !important;
    /* padding-bottom: 30px; */
  }
  .banner-content-wrapper .tagline-text {
    font-size: 20px;
    text-align: center;
  }
  .floating-images-container {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    margin: 50px;
  }

  .floating-images-container img:nth-child(1) {
    width: 120px;
    height: auto;
    border-radius: 15px;
    margin: 20px;
    box-shadow: 2px -1px 101px 3px rgba(192, 188, 188, 0.829);
    -webkit-box-shadow: 2px -1px 101px 3px rgba(240, 237, 237, 0.8);
    -moz-box-shadow: 2px -1px 101px 3px rgba(240, 237, 237, 0.8);
    animation: moveUpDown 2s infinite alternate;
    position: absolute;
    top: -20px;
    left: -40px;
    z-index: 100;
    background-color: white;
  }

  .floating-images-container img:nth-child(2) {
    width: 350px;
    box-shadow: 2px -1px 101px 3px rgb(218, 218, 218);
  }
  .floating-images-container img:nth-child(3) {
    width: 150px;
    box-shadow: 2px -1px 101px 3px rgba(192, 188, 188, 0.829);

    height: auto;
    background-color: white;

    position: absolute;
    bottom: -20px;
    right: 0;
  }
  .tagline-text {
    font-weight: 700;
  }
  .tagline-wrapper {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin-top: 40px;
  }
  .dash {
    height: 3px !important ; /* Adjust the thickness of the underline */
    background-color: orangered !important;
    text-align: center;
    width: 150px;
    margin: 0 auto;
  }
}
